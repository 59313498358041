export const litThemeObject = {
  palette: {
    primary: {
      primary: 'rgb(36,78,112)',
      dark: 'rgb(20,44,63)',
      light: '#BFA1FF',
      main: 'rgb(36,78,112)',
      contrastText: '#ffffff'
    }
  }
}
